<template>
  <div class="company-card">
    <div class="wrapper-img">
      <svg-logo width="320" height="190" v-if="company.name === 'click'" />
      <img v-else :src="require(`@/assets/img/activation/${company.name}.${company.imgType}`)">
    </div>
  </div>
</template>

<script>
import SvgLogo from '@/assets/img/logo.svg'

export default {
  name: 'CompanyCard',
  components: {
    SvgLogo
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .company-card {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 190px;
    background: $white;
    border-radius: $border-radius-large;
    background: linear-gradient(180deg, #FAFAFA 0%, #FFFFFF 100%);
    box-shadow: $box-shadow-base;
    @include max-w-xs {
      width: 280px;
      height: 160px;
    }
    .wrapper-img {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 60%;
      max-height: 80%;
      svg {
        width: 100%;
        height: 100%;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
